/* eslint-disable no-param-reassign */
import { MutationBuilder, useClientAPIMutationFn } from '@insidedesk/tuxedo';
import { useMutation } from '@tanstack/react-query';
import produce from 'immer';
import { queryClient } from '../clients';
import {
  ClaimCall,
  ClaimDetails,
  PostCallVariables,
  Writeable,
} from '../types';

export default function usePostCall(claimId: string) {
  // XXX Because MutationBuilder must know the update key at configuration time,
  // this is restricted to a single call for now. If in the future we need to
  // post multiple calls at once, we could modify MutationBuilder.delay to
  // accept a function Variables => UpdateKey.
  const rawMutationFn = useClientAPIMutationFn<ClaimCall, PostCallVariables>(
    `calls/`,
    { method: 'POST' },
  );
  const mutationFn = () =>
    rawMutationFn({ claim_ids: [parseInt(claimId, 10)] });
  const config = new MutationBuilder(queryClient, { mutationFn })
    .delayed(
      ['claims', claimId, 'details'],
      produce((draft: Writeable<ClaimDetails>) => {
        draft.facility.current_call_credits =
          (draft.facility.current_call_credits ?? 1) - 1;
      }),
    )
    .delayed(
      ['calls'],
      produce((draft: Writeable<ClaimCall[]>, call: ClaimCall) => {
        draft.forEach((draftCall) => {
          if (draftCall.id === call.id) {
            draftCall.updated = call.updated;
            draftCall.status = call.status;
            draftCall.reference = call.reference;
          }
        });
      }),
    )
    .invalidate(['facilities'])
    .configure();
  return useMutation(config);
}
