import { FlagSet } from '@insidedesk/tuxedo';
import {
  CATEGORY_OPTIONS,
  ClaimFilterValues,
  ClaimListDrawerFilters,
  ClaimsTabFilterOption,
  CLAIMS_TAB_OPTIONS,
  PatchClaimUIStateVariables,
  Sort,
  SortAttribute,
  SortDirection,
} from './types';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const DEFAULT_CATEGORY = CATEGORY_OPTIONS[0]!;
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const DEFAULT_CLAIMS_TAB: ClaimsTabFilterOption = 'issues';

export const DEFAULT_CLAIM_FILTERS: ClaimFilterValues = {
  arAge: [],
  portal: [],
  submittedAmount: [],
  originalInsurerName: [],
  workflow: [],
  taxId: [],
  responseStatus: [],
  expectedAmount: [],
};

export const DEFAULT_DRAWER_FILTERS: ClaimListDrawerFilters = {
  ...DEFAULT_CLAIM_FILTERS,
  dosStart: null,
  dosEnd: null,
  paidSubscriber: false,
  updated: false,
  postedNotClosed: false,
};

export { CATEGORY_OPTIONS, CLAIMS_TAB_OPTIONS };

export const SORT_OPTION_FLAG_MAP: Partial<
  Record<SortAttribute, keyof FlagSet>
> = {
  insurer: 'claimListInsurerSort',
  last_worked: 'claimListLastWorkedSort',
};

export const SORT_ATTRIBUTE_LABELS: Record<SortAttribute, string> = {
  ar_age: 'Ar Age',
  amout_expected: 'Expected Amount',
  insurer: 'Insurer',
  last_worked: 'Last Worked',
  patient: 'Patient Last Name',
  service_date: 'Date of Service',
  submitted_date: 'Submission Date',
};
export const DEFAULT_SORT_DIRECTION: Record<SortAttribute, SortDirection> = {
  ar_age: SortDirection.Descending,
  amout_expected: SortDirection.Descending,
  insurer: SortDirection.Ascending,
  last_worked: SortDirection.Ascending,
  patient: SortDirection.Ascending,
  service_date: SortDirection.Ascending,
  submitted_date: SortDirection.Ascending,
};
export const DEFAULT_SORT: Sort = {
  attribute: 'service_date',
  direction: DEFAULT_SORT_DIRECTION.service_date,
};

export const TAB_LABELS: Record<ClaimsTabFilterOption, string> = {
  all: 'All',
  claim_batched_not_sent: 'Claim Batched Not Sent',
  denial: 'Denials',
  eob: 'EOB',
  escalated: 'Escalated',
  expiring_soon: 'Expiring Soon',
  expired: 'Expired',
  in_progress: 'In Process',
  issues: 'Issues',
  open: 'Open',
  others: 'Others',
  paid_in_full: 'Claim Paid in Full',
  payor_portal_issues: 'Payor Portal Issues',
  snoozed: 'Snoozed',
  worked: 'Worked',
};

export const DETAILS_KEYBINDINGS = {
  back: 'ArrowLeft',
  forward: 'ArrowRight',
};

export const PAYMENT_TYPE_OPTIONS: NonNullable<
  PatchClaimUIStateVariables['payment_type']
>[] = ['AUTO', 'MANUAL'];

export const PAYMENT_FREQUENCY_OPTIONS = [30, 45, 60, 90, 105, 190, 375];
