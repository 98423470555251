import { ProductName, SyncProgress } from '@insidedesk/tuxedo';
import { Button, CardContent, CardHeader } from '@mui/material';
import { useState } from 'react';
import { useCalls, useFacilityFilterOptions } from '../../../hooks';
import { ClaimDetails } from '../../../types';
import CallButton from '../../CallButton/CallButton';
import CallDialog from '../../CallDialog/CallDialog';
import CardWithExpiredPopup from '../CardWIthExpiredPopup';

export default function CallCard(props: { claimDetails: ClaimDetails }) {
  const { claimDetails } = props;

  const claimCallsQuery = useCalls({ claimIds: [String(claimDetails.id)] });
  const facilityFilterOptionsQuery = useFacilityFilterOptions();
  const [dialogOpen, setDialogOpen] = useState(false);

  const allProducts = new Set(
    facilityFilterOptionsQuery.data?.flatMap((option) => option.products),
  );
  const isLoading =
    claimCallsQuery.isLoading || facilityFilterOptionsQuery.isLoading;

  return (
    <CardWithExpiredPopup
      variant='flat'
      color='accent-secondary'
      queries={[claimCallsQuery, facilityFilterOptionsQuery]}
    >
      <CardHeader
        title={<ProductName product='dial' twotone />}
        subheader={claimCallsQuery.isLoading ? <SyncProgress /> : null}
        subheaderTypographyProps={{ flexDirection: 'row-reverse' }}
      />
      <CardContent>
        {isLoading ? (
          <Button variant='contained' disabled sx={{ width: '100%' }}>
            Loading call data...
          </Button>
        ) : (
          <CallButton
            call={claimCallsQuery.data?.[0] ?? null}
            sx={{ width: '100%' }}
            onClick={() => setDialogOpen(true)}
          />
        )}
      </CardContent>
      <CallDialog
        claimId={String(claimDetails.id)}
        facility={claimDetails.facility}
        categories={claimDetails.categories}
        collector={claimDetails.mapped_collector}
        allProducts={allProducts}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </CardWithExpiredPopup>
  );
}
