import { ClaimListFilterValues } from '../types';

type URLSearchParamsInit = ConstructorParameters<typeof URLSearchParams>[0];
/**
 * Several claims endpoints (/claims, /claims/num-claims, etc.) share similar
 * parameters. This function is used to generate URLSearchParams for those
 * endpoints.
 */
export default function makeClaimSearchParams(
  filters: Partial<ClaimListFilterValues>,
  init?: URLSearchParamsInit,
) {
  const searchParams = new URLSearchParams(init);
  if (filters.facility) {
    searchParams.set('facility_id', filters.facility.id.toString());
  }
  if (filters.category && filters.category.id !== 'all') {
    searchParams.set('claim_categories', filters.category.id.toString());
  }
  if (filters.claimsTab) {
    searchParams.set('claims_tab', filters.claimsTab);
  }
  if (filters.searchQuery) {
    searchParams.set('searchQuery', filters.searchQuery);
  }

  (filters.portal ?? []).forEach((option) =>
    searchParams.append('portal_name', option.id),
  );
  (filters.originalInsurerName ?? []).forEach((option) =>
    searchParams.append('insurer', option.id),
  );

  (filters.responseStatus ?? []).forEach((option) =>
    searchParams.append('status_code', option.id),
  );
  (filters.submittedAmount ?? []).forEach((option) =>
    searchParams.append('submitted_amount', option.id),
  );
  (filters.expectedAmount ?? []).forEach((option) =>
    searchParams.append('expected_amount', option.id),
  );
  (filters.arAge ?? []).forEach((option) =>
    searchParams.append('ar_age', option.id),
  );
  (filters.workflow ?? []).forEach((option) =>
    searchParams.append('workflow_state', option.id),
  );
  (filters.taxId ?? []).forEach((option) =>
    searchParams.append('tax_id', option.id),
  );
  if (filters.paidSubscriber) {
    // XXX doesn't actually work
    searchParams.append('claim_categories', 'paid_subscriber');
  }
  if (filters.updated) {
    searchParams.set('updated_only', 'true');
  }
  if (filters.dosStart) {
    searchParams.set(
      'service_date_start',
      filters.dosStart.format('YYYY-MM-DD'),
    );
  }
  if (filters.dosEnd) {
    searchParams.set('service_date_end', filters.dosEnd.format('YYYY-MM-DD'));
  }
  if (filters.postedNotClosed) {
    searchParams.set('posted_not_closed', 'true');
  }
  // XXX Add remaining params here as needed
  return searchParams;
}
