import { useClientAPIQueryFn, useFlags } from '@insidedesk/tuxedo';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { DEFAULT_SORT, SORT_OPTION_FLAG_MAP } from '../constants';
import {
  claimListFiltersAtom,
  claimListPendingAtom,
  pageAtom,
  rowsPerPageAtom,
  sortsAtom,
  totalItemsAtom,
} from '../state';
import { ClaimList as ClaimListData, DUMMY_FLAG, Sort } from '../types';
import { makeClaimSearchParams } from '../utils';

function makeSortParams(sorts: Sort[]) {
  return sorts.map(({ attribute, direction }) => `${attribute},${direction}`);
}

function useQueryParams(pageOffset: number = 0) {
  const filters = useAtomValue(claimListFiltersAtom);
  const page = useAtomValue(pageAtom);
  const rowsPerPage = useAtomValue(rowsPerPageAtom);
  const sorts = useAtomValue(sortsAtom);

  const flags = useFlags();

  let enabledSorts = sorts.filter((sort) => {
    if (sort.attribute in SORT_OPTION_FLAG_MAP) {
      return flags[SORT_OPTION_FLAG_MAP[sort.attribute] ?? DUMMY_FLAG];
    }
    return true;
  });
  if (enabledSorts.length === 0) {
    enabledSorts = [DEFAULT_SORT];
  }

  return useMemo(
    () =>
      makeClaimSearchParams(
        _.omit(filters, [
          !flags.claimListTaxIdFilter ? 'taxId' : '',
          !flags.claimListPostedNotClosedFilter ? 'postedNotClosed' : '',
        ]),
        [
          ['limit', rowsPerPage.toString()],
          ['offset', ((page + pageOffset) * rowsPerPage).toString()],
          ...makeSortParams(enabledSorts).map((param) => ['sort', param]),
        ],
      ),
    [
      flags.claimListTaxIdFilter,
      flags.claimListPostedNotClosedFilter,
      filters,
      rowsPerPage,
      page,
      pageOffset,
      enabledSorts,
    ],
  );
}

export function useClaimList() {
  const queryClient = useQueryClient();
  const pending = useAtomValue(claimListPendingAtom);
  const setTotalItems = useSetAtom(totalItemsAtom);

  const queryParams = useQueryParams();
  const queryFn = useClientAPIQueryFn<ClaimListData>(`claims/?${queryParams}`);
  const query = useQuery({
    queryKey: ['claimList', queryParams.toString()],
    queryFn,
    enabled: !pending.claims,
    onSuccess: (data) => {
      queryClient.setQueryData(['navList', queryParams.toString()], data);
    },
  });

  const totalItems = query.data?.totalItems;
  useEffect(() => {
    if (totalItems) {
      setTotalItems(totalItems);
    }
  }, [setTotalItems, totalItems]);

  return query;
}

export function useFetchNextNavList() {
  const queryClient = useQueryClient();
  const queryParams = useQueryParams(1);
  const queryFn = useClientAPIQueryFn<ClaimListData>(`claims/?${queryParams}`);
  return () =>
    queryClient
      .fetchQuery({
        queryKey: ['navList', queryParams.toString()],
        queryFn,
      })
      .then((data) => {
        queryClient.setQueryData(['claimList', queryParams.toString()], data);
        return data;
      });
}

export function useFetchPrevNavList() {
  const queryClient = useQueryClient();
  const queryParams = useQueryParams(-1);
  const queryFn = useClientAPIQueryFn<ClaimListData>(`claims/?${queryParams}`);
  return () =>
    queryClient
      .fetchQuery({
        queryKey: ['navList', queryParams.toString()],
        queryFn,
      })
      .then((data) => {
        queryClient.setQueryData(['claimList', queryParams.toString()], data);
        return data;
      });
}

export function useNavList(enabled: boolean = true) {
  const queryParams = useQueryParams();

  const queryFn = useClientAPIQueryFn<ClaimListData>(`claims/?${queryParams}`);
  return useQuery({
    queryKey: ['navList', queryParams.toString()],
    queryFn,
    enabled,
    refetchOnMount: false,
  });
}
