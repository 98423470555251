import {
  HTTPError,
  ProductName,
  Switch,
  TwoToneHeader,
  useHasPermission,
  useProductName,
  useScopedState,
} from '@insidedesk/tuxedo';
import variables from '@insidedesk/tuxedo/dist/styles/variables.module.scss';
import { Button, Dialog, DialogContent, Link, Typography } from '@mui/material';
import { ReactNode, useState } from 'react';
import { NewCallIcon } from '../../assets';
import { usePostCall } from '../../hooks';
import {
  ClaimCategory,
  Collector,
  Facility,
  FacilityProduct,
} from '../../types';
import { formatInsurerName, getCallGradient } from '../../utils';
import DialButton from '../DialButton';
import ModalCloseButton from '../ModalCloseButton';

export default function CallDialog(props: {
  claimId: string;
  categories: readonly ClaimCategory[];
  facility: Facility;
  collector: Collector | null;
  allProducts: Set<FacilityProduct>;
  open: boolean;
  onClose: () => void;
}) {
  const {
    claimId,
    categories,
    facility,
    collector,
    allProducts,
    open,
    onClose,
  } = props;
  const { hasPermission } = useHasPermission();

  const mutation = usePostCall(claimId);

  type ContentState =
    | 'product_info'
    | 'facility_not_authorized'
    | 'user_not_authorized'
    | 'ortho'
    | 'out_of_calls'
    | 'confirm_call'
    | 'call_failed'
    | 'claim_unsupported'
    | 'collector_unsupported';

  const [contentState, setContentState] = useScopedState((): ContentState => {
    if (!allProducts.has('inside_dial')) {
      return 'product_info';
    }
    if (!facility.products.includes('inside_dial')) {
      return 'facility_not_authorized';
    }
    if (!hasPermission('write:inside-dial')) {
      return 'user_not_authorized';
    }
    if (categories.includes('ortho')) {
      return 'ortho';
    }
    if (!collector?.insidedial_supported) {
      return 'collector_unsupported';
    }
    if ((facility.current_call_credits ?? 0) <= 0) {
      return 'out_of_calls';
    }
    return 'confirm_call';
  }, open);
  const [errorMessage, setErrorMessage] = useState<string>();

  const handleConfirmCall = () =>
    mutation
      .mutateAsync(null)
      .then(onClose)
      .catch((err) => {
        if (err instanceof HTTPError) {
          err.response.json().then((body) => {
            if (body.description === 'Not enough credits') {
              setContentState('out_of_calls');
            } else if (err.response.status === 501) {
              setContentState('claim_unsupported');
              setErrorMessage(body.description);
            } else {
              setContentState('call_failed');
            }
          });
        } else {
          setContentState('call_failed');
        }
      });

  const cases: { value: ContentState; component: ReactNode }[] = [
    {
      value: 'product_info',
      component: <ProductInfoContent onClose={onClose} />,
    },
    {
      value: 'facility_not_authorized',
      component: <FacilityNotAuthorizedDialogContent />,
    },
    {
      value: 'user_not_authorized',
      component: <UserNotAuthorizedDialogContent />,
    },
    { value: 'ortho', component: <OrthoDialogContent /> },
    { value: 'out_of_calls', component: <OutOfCallsDialogContent /> },
    {
      value: 'confirm_call',
      component: (
        <ConfirmCallDialogContent
          onConfirm={handleConfirmCall}
          onClose={onClose}
          isLoading={mutation.isLoading}
        />
      ),
    },
    {
      value: 'call_failed',
      component: (
        <CallFailedDialogContent
          onConfirm={handleConfirmCall}
          onClose={onClose}
          isLoading={mutation.isLoading}
        />
      ),
    },
    {
      value: 'claim_unsupported',
      component: (
        <CallUnsupportedContent
          errorText={errorMessage ?? ''}
          onClose={onClose}
        />
      ),
    },
    {
      value: 'collector_unsupported',
      component: <UnsupportedCollectorDialogContent collector={collector} />,
    },
  ];

  const creditsVisible = [
    'call_failed',
    'out_of_calls',
    'confirm_call',
  ].includes(contentState);
  const callCredits = facility.current_call_credits ?? 0;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
      <ModalCloseButton onClick={onClose} />
      <DialogContent
        sx={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          justifyContent: 'space-evenly',
          paddingInline: 12,
          paddingTop: 6,
        }}
      >
        <Switch expression={contentState} cases={cases} />
      </DialogContent>
      {creditsVisible && <CallCreditsIndicator callCredits={callCredits} />}
    </Dialog>
  );
}

function CallDialogContent(props: {
  title: string[];
  info?: ReactNode;
  warning?: ReactNode;
  error?: ReactNode;
  action?: ReactNode;
}) {
  const { title, info, warning, error, action } = props;
  return (
    <>
      <TwoToneHeader
        fontSize='2.375rem'
        fontWeight={variables.weightSemiBold}
        text={title}
      />
      {error && (
        <Typography
          color='error'
          fontSize='1.625rem'
          fontWeight={variables.weightSemiBold}
        >
          {error}
        </Typography>
      )}
      {info && (
        <Typography fontWeight={variables.weightSemiBold}>{info}</Typography>
      )}
      {warning && (
        <Typography color='secondary' fontWeight={variables.weightSemiBold}>
          {warning}
        </Typography>
      )}
      {action}
    </>
  );
}

function ProductInfoContent(props: { onClose: () => void }) {
  const { onClose } = props;
  return (
    <CallDialogContent
      title={useProductName('dial')}
      info={
        <>
          <ProductName product='dial' /> will automatically call the insurance
          company and attain the existing claim status and any relevant data. It
          can take up to 2 business days before you will see your response for
          this claim.
        </>
      }
      warning={
        <>
          Please contact your account administrator to purchase{' '}
          <ProductName product='dial' /> or email{' '}
          <Link color='secondary' href='mailto:support@insidedesk.com'>
            support@insidedesk.com
          </Link>{' '}
          for assistance.
        </>
      }
      action={<Button onClick={onClose}>Close</Button>}
    />
  );
}

function FacilityNotAuthorizedDialogContent() {
  return (
    <CallDialogContent
      title={['Facility', ' Not', ' Authorized']}
      warning={
        <>
          If you believe this is an error please contact your account
          administrator or email{' '}
          <Link color='secondary' href='mailto:support@insidedesk.com'>
            support@insidedesk.com
          </Link>{' '}
          for assistance.
        </>
      }
    />
  );
}

function UserNotAuthorizedDialogContent() {
  return (
    <CallDialogContent
      title={['User', ' Not', ' Authorized']}
      warning={
        <>
          If you believe this is an error please contact your account
          administrator or email{' '}
          <Link color='secondary' href='mailto:support@insidedesk.com'>
            support@insidedesk.com
          </Link>{' '}
          for assistance.
        </>
      }
    />
  );
}

function OrthoDialogContent() {
  return (
    <CallDialogContent
      title={['Coming', ' Soon']}
      warning={
        <>
          We don&apos;t currently service ortho claims but if you&apos;d like to
          request it please contact your manager/Director or please email{' '}
          <Link color='secondary' href='mailto:support@insidedesk.com'>
            support@insidedesk.com
          </Link>{' '}
          for assistance.
        </>
      }
    />
  );
}

function UnsupportedCollectorDialogContent({
  collector,
}: {
  collector: Collector | null;
}) {
  return (
    <CallDialogContent
      title={['Coming', ' Soon']}
      warning={
        <>
          We don&apos;t currently service claims for{' '}
          {collector?.name ? formatInsurerName(collector.name) : 'this'}{' '}
          insurance. Our support team will notify you once support becomes
          available. If you have any questions or concerns please reach out to
          our support team via email at{' '}
          <Link color='secondary' href='mailto:support@insidedesk.com'>
            support@insidedesk.com
          </Link>
          .
        </>
      }
    />
  );
}

function OutOfCallsDialogContent() {
  return (
    <CallDialogContent
      title={['Out', ' Of', ' Calls']}
      error='You have no calls remaining'
      warning={
        <>
          Please contact your account administrator to purchase{' '}
          <ProductName product='dial' /> or email{' '}
          <Link color='secondary' href='mailto:support@insidedesk.com'>
            support@insidedesk.com
          </Link>{' '}
          for assistance.
        </>
      }
    />
  );
}

function ConfirmCallDialogContent(props: {
  onConfirm: () => void;
  onClose: () => void;
  isLoading: boolean;
}) {
  const { onConfirm, onClose, isLoading } = props;

  return (
    <CallDialogContent
      title={['Confirm', ' Call(s)']}
      info={
        <>
          <ProductName product='dial' /> will automatically call the insurance
          company and attain the existing claim status and any relevant data. It
          can take up to 2 business days before you will see your response for
          this claim.
        </>
      }
      action={
        <>
          <DialButton
            Icon={NewCallIcon}
            buttonText='Confirm and call now'
            gradient={getCallGradient(null)}
            onClick={onConfirm}
            disabled={isLoading}
          />
          <Button onClick={onClose}>Cancel</Button>
        </>
      }
    />
  );
}

function CallFailedDialogContent(props: {
  onConfirm: () => void;
  onClose: () => void;
  isLoading: boolean;
}) {
  const { onConfirm, onClose, isLoading } = props;

  return (
    <CallDialogContent
      title={['Call', ' Failed']}
      error={
        <>
          <ProductName product='dial' /> Call Failed, Please try again
        </>
      }
      action={
        <>
          <DialButton
            Icon={NewCallIcon}
            buttonText='Confirm and call now'
            gradient={getCallGradient(null)}
            onClick={onConfirm}
            disabled={isLoading}
          />
          <Button onClick={onClose}>Cancel</Button>
        </>
      }
    />
  );
}

function CallUnsupportedContent(props: {
  errorText: string;
  onClose: () => void;
}) {
  const { errorText, onClose } = props;
  return (
    <CallDialogContent
      title={['Claim', ' Unsupported']}
      error={
        <>
          This claim is not supported by <ProductName product='dial' />
        </>
      }
      action={
        <>
          <div>{errorText}</div>
          <Button onClick={onClose}>Cancel</Button>
        </>
      }
    />
  );
}

function CallCreditsIndicator(props: { callCredits: number }) {
  const { callCredits } = props;
  return (
    <Typography
      alignSelf='center'
      border={variables.borderLight}
      fontSize='0.875rem'
      paddingInline={2}
      paddingBlock={1}
      textTransform='uppercase'
      sx={{
        borderTopLeftRadius: variables.borderRadius,
        borderTopRightRadius: variables.borderRadius,
      }}
    >
      {callCredits} {callCredits === 1 ? 'Call' : 'Calls'} Left
    </Typography>
  );
}
