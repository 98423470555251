import { useAuth0 } from '@auth0/auth0-react';
import {
  BlurPHIProvider,
  ClientAPIProvider,
  CommonUtilityWrapper,
  FlagProvider,
  MainNavbar,
  provider,
  ProviderStack,
  SnackbarProvider,
  useRefreshToken,
  UserProfileDevtools,
  UserProfileProvider,
} from '@insidedesk/tuxedo';
import { Box, Stack } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import { Outlet } from 'react-router-dom';
import { queryClient } from '../clients';
import {
  API_CONFIG,
  FULL_STORY_CONFIG,
  NAVBAR_CONFIG,
  PENDO_CONFIG,
} from '../config';

const pendoConfig = {
  stageId: PENDO_CONFIG.stageId,
};

function resetQueries() {
  queryClient.removeQueries({ queryKey: [] });
}

export default function Root() {
  const { logout } = useAuth0();

  const tokenPromise = useRefreshToken(resetQueries);

  return (
    <ProviderStack
      providers={[
        provider(ClientAPIProvider, {
          baseURL: API_CONFIG.clientApiUrl,
          tokenPromise: () => tokenPromise,
        }),
        provider(UserProfileProvider, { tokenPromise }),
        provider(UserProfileDevtools, {}),
        provider(QueryClientProvider, { client: queryClient }),
        provider(FlagProvider, {
          clientSideID: process.env.REACT_APP_LD_CLIENT_ID ?? '',
          useClientContext: true,
        }),
        provider(SnackbarProvider, {}),
        provider(BlurPHIProvider, {}),
      ]}
    >
      <CommonUtilityWrapper
        pendoConfig={pendoConfig}
        fullStoryConfigOptions={FULL_STORY_CONFIG}
      />
      <Stack height='100%' minWidth='1440px'>
        <MainNavbar
          active='assist'
          assist={NAVBAR_CONFIG.assistUrl}
          credentials={NAVBAR_CONFIG.credentialsUrl}
          iq={NAVBAR_CONFIG.iqUrl}
          remit={NAVBAR_CONFIG.remitUrl}
          users={NAVBAR_CONFIG.usersUrl}
          help={NAVBAR_CONFIG.helpUrl}
          logout={() => logout({ returnTo: window.location.origin })}
          showBlurToggle
        />
        <Box display='flex' flexDirection='column' flexGrow={1} overflow='auto'>
          <Outlet />
        </Box>
      </Stack>
    </ProviderStack>
  );
}
