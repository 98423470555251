import { isNotNullish } from '@insidedesk/tuxedo';
import { Card, CardContent, CardHeader, Container } from '@mui/material';
import _ from 'lodash';
import { useMemo } from 'react';
import { ClaimDetails, ClaimResponseEntry } from '../../../types';

export default function AdjudicationReasonsCard({
  claimDetails,
  entry,
}: {
  claimDetails: ClaimDetails;
  entry?: ClaimResponseEntry;
}) {
  const isClearingHouseResponse =
    claimDetails.best_response.collector_type === 'clearinghouse';
  const title = isClearingHouseResponse
    ? 'Clearinghouse Notes'
    : 'Insurer Notes';

  const adjudicationReasons = useMemo(
    () =>
      _.uniq(
        (entry?.adjudications ?? []).flatMap((adjudication) =>
          adjudication.reasons
            /**
             * The backend is handling clearinghouse notes the same way as
             * insurer notes hence still using the insurer discriminator.
             */
            .filter((reason) => reason.discriminator === 'insurer')
            .map((reason) => reason.text)
            .filter(isNotNullish),
        ),
      ),
    [entry?.adjudications],
  );

  const isDialResponse = claimDetails.best_response.type === 'inside_dial';
  const entryNotes = isDialResponse && entry?.notes;

  if (!entryNotes && adjudicationReasons.length === 0) return null;

  return (
    <Card
      data-testid='adjudication-reasons-card'
      variant='outlined'
      color='accent-secondary'
    >
      <CardHeader title={title} />
      <CardContent>
        <Container maxWidth='md'>
          {adjudicationReasons.length > 0 && (
            <ol>
              {adjudicationReasons.map((reason) => (
                <li key={reason}>{reason}</li>
              ))}
            </ol>
          )}
          {entryNotes}
        </Container>
      </CardContent>
    </Card>
  );
}
