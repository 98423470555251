import { useClientAPIQueryFn, useFlags } from '@insidedesk/tuxedo';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import _ from 'lodash';
import { claimListFiltersAtom, claimListPendingAtom } from '../state';
import { ClaimFilterOptions, ClaimFilterValues } from '../types';
import { formatTaxId } from '../utils';
import makeClaimSearchParams from '../utils/makeClaimSearchParams';

export default function useClaimFilterOptions(
  additionalOptions?: UseQueryOptions<
    ClaimFilterOptions,
    unknown,
    ClaimFilterValues
  >,
) {
  const flags = useFlags();
  const filters = useAtomValue(claimListFiltersAtom);
  const pending = useAtomValue(claimListPendingAtom);
  const searchParams = makeClaimSearchParams(_.pick(filters, 'facility'));
  const queryFn = useClientAPIQueryFn<ClaimFilterOptions>(
    `claims/ui-claim-filter-options/?${searchParams}`,
  );
  return useQuery({
    queryKey: [
      'claimList',
      'filterOptions',
      searchParams.toString(),
      flags.claimListTaxIdFilter,
    ],
    queryFn,
    select: (data): ClaimFilterValues => ({
      arAge: data.ar_age_options.map((age) => ({ id: age, label: age })),
      portal: data.portals.map((portal) => ({ id: portal, label: portal })),
      submittedAmount: data.submitted_amount_options.map((amount) => ({
        id: amount,
        label: amount,
      })),
      originalInsurerName: data.original_insurer_names.map((insurer) => ({
        id: insurer,
        label: insurer,
      })),
      workflow: data.workflow_options.map((workflow) => ({
        id: workflow,
        label: workflow,
      })),
      taxId: flags.claimListTaxIdFilter
        ? data.tax_id_options.map((taxId) => ({
            id: taxId,
            label: formatTaxId(taxId),
          }))
        : [],
      responseStatus: Object.entries(data.status_options).map(
        ([id, status]) => ({
          id,
          label: status.name,
        }),
      ),
      expectedAmount: data.expected_amount_options.map((amount) => ({
        id: amount,
        label: amount,
      })),
    }),
    ...additionalOptions,
    enabled:
      !pending.claimFilterOptions && (additionalOptions?.enabled ?? true),
  });
}
