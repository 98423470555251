import {
  MultiSelectChip,
  ResetButton,
  SidebarItem,
  SidebarItemProps,
  SidebarItems,
  SingleSelect,
  useFlags,
} from '@insidedesk/tuxedo';
import variables from '@insidedesk/tuxedo/dist/styles/variables.module.scss';
import {
  alpha,
  Box,
  Card,
  CardContent,
  Divider,
  List,
  ListProps,
  Stack,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { ReactNode, useId } from 'react';
import { TAB_LABELS } from '../../constants';
import { useClaimCounts } from '../../hooks';
import {
  claimListFiltersAtom,
  hasDrawerFiltersAtom,
  resetDrawerFiltersAtom,
} from '../../state';
import {
  CATEGORY_OPTIONS,
  ClaimListDrawerFilters,
  ClaimsTabFilterOption,
} from '../../types';

type TabSpec = {
  id: ClaimsTabFilterOption;
  countable?: true;
} & Omit<SidebarItemProps<'button'>, 'id' | 'label' | 'active'>;

const TAB_SPECS: { label: string; tabs: TabSpec[]; header?: string }[] = [
  {
    label: 'Tabs',
    tabs: [
      { id: 'issues', countable: true },
      { id: 'eob', countable: true },
      { id: 'others', countable: true },
      { id: 'snoozed', countable: true },
      { id: 'in_progress', countable: true },
      { id: 'open', countable: true },
      { id: 'payor_portal_issues', countable: true, color: 'error' },
      { id: 'worked', countable: true },
      { id: 'escalated', countable: true, color: 'error' },
    ],
  },
  {
    label: 'Filtered View',
    tabs: [
      { id: 'claim_batched_not_sent', countable: true },
      { id: 'paid_in_full', countable: true },
      { id: 'denial', countable: true },
      { id: 'expiring_soon', countable: true },
      { id: 'expired', countable: true },
    ],
    header: 'Filtered View',
  },
  { label: 'All', tabs: [{ id: 'all' }] },
];

function SidebarHeader(props: TypographyProps) {
  const theme = useTheme();
  return (
    <Typography
      variant='h6'
      fontWeight={variables.weightMedium}
      color='primary.main'
      bgcolor={alpha(theme.palette.primary.main, 0.05)}
      padding={1}
      borderTop={variables.borderMain}
      borderBottom={variables.borderMain}
      {...props}
    />
  );
}

function SidebarSections(props: ListProps) {
  return <List {...props} />;
}

function SidebarSection(props: { header?: string; children: ReactNode }) {
  const { header, children } = props;
  const theme = useTheme();
  const headerId = useId();
  return (
    <Box width='100%'>
      {header && (
        <SidebarHeader id={headerId} fontWeight={variables.weightBold}>
          {header}
        </SidebarHeader>
      )}
      <SidebarItems
        aria-labelledby={header ? headerId : undefined}
        sx={{
          bgcolor: header ? alpha(theme.palette.primary.main, 0.05) : undefined,
        }}
      >
        {children}
      </SidebarItems>
      {header && <Divider />}
    </Box>
  );
}

type FilterChipsProps = {
  name: keyof ClaimListDrawerFilters;
  label: string;
};
function FilterChips(props: FilterChipsProps) {
  const { name, label } = props;
  const [filters, updateFilters] = useAtom(claimListFiltersAtom);

  const values = filters[name];
  if (Array.isArray(values)) {
    return (
      <>
        {values.map((value) => (
          <MultiSelectChip
            key={value.id}
            label={`${label}: ${value.label}`}
            onDelete={() =>
              updateFilters({
                [name]: values.filter((option) => option.id !== value.id),
              })
            }
          />
        ))}
      </>
    );
  }

  return values ? (
    <MultiSelectChip
      label={label}
      onDelete={() =>
        updateFilters({
          [name]: ['dosStart', 'dosEnd'].includes(name) ? null : false,
        })
      }
    />
  ) : null;
}

export default function LeftSidebar() {
  const flags = useFlags();
  const [filters, updateFilters] = useAtom(claimListFiltersAtom);
  const hasDrawerFilters = useAtomValue(hasDrawerFiltersAtom);
  const resetDrawerFilters = useSetAtom(resetDrawerFiltersAtom);

  const countsQuery = useClaimCounts(
    TAB_SPECS.flatMap(({ tabs }) => tabs)
      .filter((spec) => spec.countable)
      .map((spec) => spec.id),
  );

  return (
    <Card
      variant='outlined'
      sx={{
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeft: 'none',
        height: '100%',
      }}
    >
      <CardContent sx={{ px: 0, py: 2, height: '100%', overflow: 'auto' }}>
        <Stack>
          <SingleSelect
            data-testid='category-select'
            label='Category'
            options={CATEGORY_OPTIONS}
            value={filters.category}
            onChange={(e, value) => updateFilters({ category: value })}
            sx={{ px: 2 }}
            size='small'
            disableClearable
          />
          <SidebarSections aria-label='Tabs'>
            {TAB_SPECS.map(({ label, tabs, header }) => (
              <SidebarSection header={header} key={label}>
                {tabs
                  .filter(
                    (tab) =>
                      flags.filteredViewPaidInFull || tab.id !== 'paid_in_full',
                  )
                  .map((spec) => {
                    const { countable, id, ...rest } = spec;
                    return (
                      <SidebarItem
                        key={id}
                        data-fs-element={`claim-list-tab-${id}`}
                        label={TAB_LABELS[id]}
                        count={countsQuery.data?.get(id)}
                        onClick={() => updateFilters({ claimsTab: id })}
                        active={filters.claimsTab === id}
                        aria-current={
                          filters.claimsTab === id ? 'page' : undefined
                        }
                        {...rest}
                      />
                    );
                  })}
              </SidebarSection>
            ))}
          </SidebarSections>
          {hasDrawerFilters && (
            <>
              <SidebarHeader>Applied Filters</SidebarHeader>
              <Stack px={2} direction='row' gap={1} flexWrap='wrap'>
                <FilterChips name='dosStart' label='DoS Start' />
                <FilterChips name='dosEnd' label='DoS End' />
                <FilterChips name='portal' label='Portal' />
                <FilterChips name='originalInsurerName' label='Insurer' />
                <FilterChips name='responseStatus' label='Response' />
                <FilterChips name='submittedAmount' label='Submitted Amount' />
                <FilterChips name='expectedAmount' label='Expected Amount' />
                <FilterChips name='arAge' label='Ar Age' />
                <FilterChips name='workflow' label='Workflow Status' />
                {flags.claimListTaxIdFilter && (
                  <FilterChips name='taxId' label='Tax ID' />
                )}
                <FilterChips
                  name='paidSubscriber'
                  label='Paid Subscriber Claims Only'
                />
                <FilterChips name='updated' label='Updated Claims Only' />
                {flags.claimListPostedNotClosedFilter && (
                  <FilterChips
                    name='postedNotClosed'
                    label='Posted But Not Closed'
                  />
                )}
                <ResetButton onClick={resetDrawerFilters}>Clear</ResetButton>
              </Stack>
            </>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
