import { formatTitle, PHI } from '@insidedesk/tuxedo';
import {
  CardContent,
  Divider,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { Fragment } from 'react';
import unreachable from 'ts-unreachable';
import { CardWithExpiredPopup } from '..';
import { useClaimHistory } from '../../../hooks';
import '../../../theme';
import { User } from '../../../types';
import { formatTime } from '../../../utils';

type ChangeEventProps = {
  title: string;
  timestamp: string;
  details?: { user: User | null; action: string };
};
function ChangeEvent({ title, timestamp, details }: ChangeEventProps) {
  return (
    <ListItem sx={{ py: 2, px: 3 }}>
      <div>
        <Typography variant='body1'>{title}</Typography>
        <Typography variant='caption' display='block' mt='-0.3em' gutterBottom>
          {formatTime(timestamp)}
        </Typography>
        {details !== undefined && (
          <Typography variant='body2'>
            <PHI>{details.user?.name ?? 'System'}</PHI> {details.action}
          </Typography>
        )}
      </div>
    </ListItem>
  );
}

export default function ClaimHistoryCard({ claimId }: { claimId: string }) {
  const claimHistoryQuery = useClaimHistory(claimId);

  const events: ChangeEventProps[] = (claimHistoryQuery.data ?? []).map(
    (event) => {
      if (event.event_type === 'CLAIM_UPDATE') {
        return { title: 'Claim Updated', timestamp: event.timestamp };
      }
      if (event.event_type === 'CLAIM_NOTE_ADDED') {
        return {
          title: 'Note Added',
          timestamp: event.timestamp,
          details: { user: event.user, action: 'added a note.' },
        };
      }
      if (event.event_type === 'CLAIM_WORKFLOW_SET') {
        return {
          title: 'Workflow Updated',
          timestamp: event.timestamp,
          details: {
            user: event.user,
            action: `updated workflow state to ${formatTitle(
              event.workflow_state?.state ?? 'none',
            )}.`,
          },
        };
      }
      if (event.event_type === 'CLAIM_DENIAL_REASON_ADDED') {
        return {
          title: 'Denial Added',
          timestamp: event.timestamp,
          details: {
            user: event.user,
            action: `added ${formatTitle(
              event.denial_reason.reason,
            )} to denial reasons.`,
          },
        };
      }
      return unreachable(event);
    },
  );

  return (
    <CardWithExpiredPopup
      variant='flat'
      color='accent-secondary'
      queries={[claimHistoryQuery]}
    >
      <CardContent sx={{ px: 0 }}>
        <List>
          {events?.map((event, index) => (
            <Fragment key={event.timestamp}>
              <ChangeEvent {...event} />
              {index + 1 !== events.length && <Divider />}
            </Fragment>
          ))}
        </List>
        {claimHistoryQuery.isSuccess && events?.length === 0 && (
          <Typography variant='body2' textAlign='center'>
            There are no changes to display
          </Typography>
        )}
      </CardContent>
    </CardWithExpiredPopup>
  );
}
