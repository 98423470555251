import { LoadingSpinner, Switch, TwoToneHeader } from '@insidedesk/tuxedo';
import { LinkOff } from '@mui/icons-material';
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  dialogContentClasses,
  DialogTitle,
  Link,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { MutationStatus } from '@tanstack/react-query';
import { ReactNode } from 'react';
import { FormContainer, SelectElement, useForm } from 'react-hook-form-mui';
import { usePostUnmatchClaimResponseEntry } from '../../../hooks';
import {
  ClaimDetails,
  ClaimResponseEntry,
  PostUnmatchVariables,
} from '../../../types';
import ModalCloseButton from '../../ModalCloseButton';

type UnmatchReason = PostUnmatchVariables['reason'];

export const UNMATCH_REASONS = [
  { id: 'PROCESSING', label: 'Claim is still in Process/Not Finalized' },
  { id: 'INCORRECT_OFFICE', label: 'Searching Incorrect Office' },
  { id: 'INCORRECT_LOGIN', label: 'Incorrect Login' },
  { id: 'INCORRECT_PATIENT', label: 'Incorrect Patient' },
  { id: 'INCORRECT_DATE_OF_SERVICE', label: 'Incorrect Date of Service' },
  { id: 'INCORRECT_PROCEDURE_CODE', label: 'Incorrect Procedure Code(s)' },
  {
    id: 'INCORRECT_CLAIM_SELECTION',
    label: 'Primary/Secondary - Incorrect Claim Selection',
  },
] as const satisfies readonly { id: UnmatchReason; label: string }[];

export default function UnmatchClaimDialog(props: {
  open: boolean;
  onClose: () => void;
  entry?: ClaimResponseEntry;
  claimId: ClaimDetails['id'];
}) {
  const { open, onClose, entry, claimId } = props;
  const theme = useTheme();
  const postUnmatchMutation = usePostUnmatchClaimResponseEntry(
    claimId,
    entry?.id,
  );
  const { status } = postUnmatchMutation;
  const { control, handleSubmit } = useForm<{ reason: UnmatchReason }>();
  const formId = 'unmatch-reason-form';

  const contentCases: { value: MutationStatus; component: ReactNode }[] = [
    {
      value: 'idle',
      component: (
        <>
          <Typography sx={{ fontWeight: 600, textAlign: 'center' }}>
            You have indicated this is not a correct claim response/claim match.
            <br />
            Please select why the response is incorrect:
          </Typography>
          <FormContainer FormProps={{ id: formId, style: { width: '100%' } }}>
            <SelectElement
              control={control}
              name='reason'
              label='Reason'
              required
              options={UNMATCH_REASONS}
              sx={{ width: '100%' }}
            />
          </FormContainer>
        </>
      ),
    },
    {
      value: 'loading',
      component: (
        <LoadingSpinner description='Please wait, delinking claim...' />
      ),
    },
    {
      value: 'success',
      component: (
        <Typography sx={{ fontWeight: 600, textAlign: 'center' }}>
          {getUnmatchReasonSuccessMessage(
            postUnmatchMutation.data?.feedback_created,
          )}
        </Typography>
      ),
    },
    {
      value: 'error',
      component: (
        <>
          <Stack alignItems='center'>
            <LinkOff
              color='secondary'
              sx={{ height: '75px !important', width: '75px !important' }}
            />
            <TwoToneHeader
              text={['delinking e', 'rr', 'or']}
              sx={{
                fontSize: '3rem',
                fontWeight: theme.typography.fontWeightMedium,
              }}
            />
          </Stack>
          <Typography sx={{ fontWeight: 600, textAlign: 'center' }}>
            Sorry, this action can not be performed at this time. Please reach
            out to{' '}
            <Link color='secondary' href='mailto:support@insidedesk.com'>
              support@insidedesk.com
            </Link>{' '}
            for assistance.
          </Typography>
        </>
      ),
    },
  ];

  const actionCases: { value: MutationStatus; component: ReactNode }[] = [
    {
      value: 'idle',
      component: (
        <Button
          form={formId}
          variant='contained'
          onClick={handleSubmit(({ reason }) =>
            postUnmatchMutation.mutate({ reason }),
          )}
        >
          Delink
        </Button>
      ),
    },
    {
      value: 'loading',
      component: (
        <Button variant='contained' disabled>
          Delinking
        </Button>
      ),
    },
    {
      value: 'success',
      component: (
        <Button variant='contained' onClick={onClose}>
          Close
        </Button>
      ),
    },
    {
      value: 'error',
      component: (
        <Button variant='contained' onClick={onClose}>
          Close
        </Button>
      ),
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='md'
      PaperProps={{ sx: { py: 4, px: 6 } }}
    >
      <ModalCloseButton onClick={onClose} />
      <DialogTitle sx={{ p: 0 }}>
        <TwoToneHeader
          text={
            status === 'success'
              ? ['', 'thank you for your feedback']
              : ['mismatched', ' claim']
          }
          icon={<LinkOff />}
        />
      </DialogTitle>
      <DialogContent
        sx={{
          [`& .${dialogContentClasses.root}`]: { pt: 6 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 4,
          mt: 6,
          mb: 3,
          // XXX: https://github.com/mui/material-ui/issues/27851
          paddingTop: `${theme.spacing(6)} !important`,
          p: 6,
          borderRadius: `${theme.shape.borderRadius}px`,
          border: `1px solid ${theme.palette.grey[200]}`,
          background: `linear-gradient(
            180deg,
            ${theme.palette.background.default} 0%,
            ${theme.palette.background.paper} 100%
          )`,
        }}
      >
        <Switch expression={status} cases={contentCases} />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Switch expression={status} cases={actionCases} />
      </DialogActions>
    </Dialog>
  );
}

export function MismatchedClaimButton(props: ButtonProps) {
  const { onClick } = props;
  return (
    <Button
      startIcon={<LinkOff sx={{ fontSize: '30px !important' }} />}
      sx={{
        px: 2,
        textTransform: 'uppercase',
        background: 'linear-gradient(180deg, #FFF 0%, #F5F5F5 100%), #FFF',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',
      }}
      onClick={onClick}
    >
      Mismatched Claim
    </Button>
  );
}

export function getUnmatchReasonSuccessMessage(feedbackCreated?: boolean) {
  const baseMessage = `
    Thank you for providing information on why the claim response/claim match is
    incorrect. This claim will be moved to the most appropriate tab based on the
    claim information currently available.
  `;
  const feedbackAddon =
    'We have also automatically submitted a claim feedback on your behalf.';

  if (feedbackCreated) {
    return `${baseMessage} ${feedbackAddon}`;
  }

  return baseMessage;
}
