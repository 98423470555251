import { defaultTheme } from '@insidedesk/tuxedo';
import variables from '@insidedesk/tuxedo/dist/styles/variables.module.scss';
import {
  createTheme,
  PaletteColor,
  PaletteColorOptions,
  ThemeOptions,
} from '@mui/material';
import _ from 'lodash';

declare module '@mui/material/styles' {
  interface Palette {
    dependent: PaletteColor;
    paidSubscriber: PaletteColor;
    postedNotClosed: PaletteColor;
  }
  interface PaletteOptions {
    dependent?: PaletteColorOptions;
    paidSubscriber?: PaletteColorOptions;
    postedNotClosed?: PaletteColorOptions;
  }
}

/**
 * SCSS is mocked out in jest tests (probably something like
 * https://jestjs.io/docs/webpack#mocking-css-modules). So if we must rely on
 * having valid colors in the tests, we need to substitute variables accesses
 * with a valid color if it is being mocked.
 */
function color(key: keyof typeof variables, default_ = '#000') {
  const paletteColor = variables[key];
  if (paletteColor === undefined) return default_;
  if (paletteColor === key) return default_;
  return paletteColor;
}

const assistTheme = {
  palette: {
    dependent: { main: color('secondaryMain') },
    paidSubscriber: { main: color('errorDark') },
    postedNotClosed: { main: color('textPrimary') },
  },
} satisfies ThemeOptions;

const theme = createTheme(_.merge(defaultTheme, assistTheme));
export default theme;
