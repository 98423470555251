import {
  LoadingSpinner,
  ProductPreviewProvider,
  useFlags,
} from '@insidedesk/tuxedo';
import CelebrationIcon from '@mui/icons-material/Celebration';
import { Box, Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { PropsWithChildren, useMemo, useState } from 'react';
import { ClaimInfoCard, SoftPopup } from '..';
import { CallButton, DataExpiredPopup, useDataExpired } from '../..';
import { useCalls, useClaimList, useFacility } from '../../../hooks';
import {
  claimListOptionsAtom,
  pageAtom,
  rowsPerPageAtom,
} from '../../../state';
import { ClaimInfo, FacilityFilterOption, StatusMapping } from '../../../types';
import { facilityInPreview } from '../../../utils';
import CallDialog from '../../CallDialog/CallDialog';

export default function ClaimList(props: { statusMapping: StatusMapping }) {
  const { statusMapping } = props;
  const flags = useFlags();

  const claimListQuery = useClaimList();

  const page = useAtomValue(pageAtom);
  const rowsPerPage = useAtomValue(rowsPerPageAtom);
  const expiredContext = useDataExpired([claimListQuery]);
  const facilityOptionById = useFacilityOptionsById();

  const claimIds =
    claimListQuery.data?.page?.map((claim) => String(claim.id)) ?? [];

  if (claimListQuery.isError) {
    return (
      <Box sx={{ margin: 'auto' }}>
        <DataExpiredPopup context={expiredContext} />
      </Box>
    );
  }

  if (claimListQuery.isLoading) {
    return (
      <Box sx={{ margin: 'auto' }}>
        <LoadingSpinner />
      </Box>
    );
  }

  if (claimListQuery.data.totalItems === 0) {
    return (
      <Box sx={{ margin: 'auto' }}>
        <NoClaims />
      </Box>
    );
  }

  return (
    <Stack direction='column' spacing={1}>
      {claimListQuery.data.page?.map((claim, index) => (
        <ProductPreviewProvider
          key={claim.id}
          preview={
            Boolean(flags.reportingOnlyProductPreview) &&
            facilityInPreview(facilityOptionById.get(claim.facility.id))
          }
        >
          <ClaimInfoCard
            claim={claim}
            statusMapping={statusMapping}
            overallIndex={page * rowsPerPage + index}
            callButton={
              flags.superDialStatus &&
              (claim.mapped_collector?.name === 'denti-cal' ||
                flags.superDialNotDenticalOnly) && (
                <CallButtonAdaptor claim={claim} claimIds={claimIds} />
              )
            }
          />
        </ProductPreviewProvider>
      ))}
    </Stack>
  );
}

function NoClaims() {
  return (
    <SoftPopup
      Icon={CelebrationIcon}
      message={
        <>
          Congrats!
          <br />
          You have no more claims
        </>
      }
    />
  );
}

function useFacilityOptionsById() {
  const options = useAtomValue(claimListOptionsAtom);
  return useMemo(() => {
    const facilityOptionById = new Map<number, FacilityFilterOption>();
    options.facility.forEach((option) => {
      facilityOptionById.set(option.id, option);
    });
    return facilityOptionById;
  }, [options.facility]);
}

function CallButtonAdaptor(props: { claim: ClaimInfo; claimIds: string[] }) {
  const { claim, claimIds } = props;

  const [open, setOpen] = useState(false);

  const claimCallsQuery = useCalls(
    { claimIds },
    { enabled: claimIds.length > 0 },
  );
  const matchingCall =
    claimCallsQuery.data?.find((call) => call.claim_id === claim.id) ?? null;

  const facilityQuery = useFacility(
    claimIds.length > 0 ? claim.facility.id : undefined,
  );

  const options = useAtomValue(claimListOptionsAtom);
  const allProducts = new Set(
    options.facility.flatMap((option) => option.products),
  );

  const isSuccess = claimCallsQuery.isSuccess && facilityQuery.isSuccess;

  return (
    <ClickBarrier click>
      <CallButton
        iconOnly
        call={isSuccess ? matchingCall : null}
        onClick={
          isSuccess
            ? (e) => {
                e.stopPropagation();
                setOpen(true);
              }
            : undefined
        }
        disabled={!isSuccess}
        showTooltip
      />
      <ClickBarrier contextmenu>
        {isSuccess && (
          <CallDialog
            allProducts={allProducts}
            categories={claim.categories}
            claimId={String(claim.id)}
            facility={facilityQuery.data}
            collector={claim.mapped_collector}
            onClose={() => setOpen(false)}
            open={open}
          />
        )}
      </ClickBarrier>
    </ClickBarrier>
  );
}

/** Because the call dialog is rendered within the claim info card, which has
 * click and contextmenu handlers, any click within the call dialog or its
 * backdrop would normally bubble up and cause navigation or context menu events
 * to occur. I couldn't find a way to prevent this within CallDialog itself,
 * and seeing as react doesn't have a way to block synthetic events without
 * mimicking the DOM event API, I had to add a wrapper div to block click and
 * contextmenu events from reaching the card.
 */
function ClickBarrier(
  props: PropsWithChildren<{ click?: boolean; contextmenu?: boolean }>,
) {
  const { click, contextmenu } = props;
  const { children } = props;
  return (
    <div
      role='presentation'
      onClick={click ? (e) => e.stopPropagation() : undefined}
      onContextMenu={contextmenu ? (e) => e.stopPropagation() : undefined}
    >
      {children}
    </div>
  );
}
