import { UseQueryOptions } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useClaimFilterOptions, useFacilityFilterOptions } from '.';
import { claimListOptionsAtom } from '../state';

export default function useHydrateClaimListOptions(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: UseQueryOptions<any>,
) {
  const hydrateOptions = useSetAtom(claimListOptionsAtom);

  const facilityFilterOptionsQuery = useFacilityFilterOptions(options);
  const claimFilterOptionsQuery = useClaimFilterOptions(options);

  useEffect(() => {
    if (facilityFilterOptionsQuery.isSuccess) {
      hydrateOptions({ facility: facilityFilterOptionsQuery.data });
    }
  }, [
    facilityFilterOptionsQuery.data,
    facilityFilterOptionsQuery.isSuccess,
    hydrateOptions,
  ]);

  useEffect(() => {
    if (claimFilterOptionsQuery.isSuccess) {
      hydrateOptions({ claimFilters: claimFilterOptionsQuery.data });
    }
  }, [
    claimFilterOptionsQuery.data,
    claimFilterOptionsQuery.isSuccess,
    hydrateOptions,
  ]);

  if (facilityFilterOptionsQuery.isError || claimFilterOptionsQuery.isError) {
    throw new Error('Error loading data');
  }
}
