import { ExtendedDivider, MultiSelect, useFlags } from '@insidedesk/tuxedo';
import CloseIcon from '@mui/icons-material/Close';
import TuneIcon from '@mui/icons-material/Tune';
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  claimListFiltersAtom,
  claimListOptionsAtom,
  resetDrawerFiltersAtom,
} from '../../state';
import { ClaimFilterOption, ClaimListDrawerFilters } from '../../types';
import DoSRangePicker from './DoSRangePicker';

type DrawerSelectProps = {
  name: {
    [key in keyof ClaimListDrawerFilters]: ClaimListDrawerFilters[key] extends ClaimFilterOption[]
      ? key
      : never;
  }[keyof ClaimListDrawerFilters];
  label: string;
};
function DrawerSelect(props: DrawerSelectProps) {
  const { name, label } = props;
  const [filters, updateFilters] = useAtom(claimListFiltersAtom);
  const options = useAtomValue(claimListOptionsAtom);
  return (
    <MultiSelect
      name={name}
      label={label}
      options={options[name]}
      values={filters[name]}
      onChange={(e, values) => updateFilters({ [name]: values })}
      size='small'
    />
  );
}

type DrawerCheckboxProps = {
  name: {
    [key in keyof ClaimListDrawerFilters]: ClaimListDrawerFilters[key] extends boolean
      ? key
      : never;
  }[keyof ClaimListDrawerFilters];
  label: string;
};
function DrawerCheckbox(props: DrawerCheckboxProps) {
  const { name, label } = props;
  const [filters, updateFilters] = useAtom(claimListFiltersAtom);
  return (
    <FormControlLabel
      label={label}
      control={
        <Checkbox
          checked={filters[name] ?? false}
          onChange={(e, checked) => updateFilters({ [name]: checked })}
        />
      }
      sx={{ margin: 0 }}
    />
  );
}

export default function FilterDrawer(props: {
  open: boolean;
  onClose: () => void;
}) {
  const { open, onClose } = props;

  const flags = useFlags();
  const reset = useSetAtom(resetDrawerFiltersAtom);

  return (
    <Drawer open={open} onClose={onClose} anchor='right'>
      <Box width={330}>
        <Toolbar>
          <TuneIcon color='primary' sx={{ mr: 1 }} />
          <Typography component='h2' fontSize='1.125rem' sx={{ flexGrow: 1 }}>
            Filters
          </Typography>
          <IconButton aria-label='Close' color='primary' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <Stack spacing={2} paddingX={2}>
          <DoSRangePicker range='dosStart' />
          <DoSRangePicker range='dosEnd' />
          <DrawerSelect name='portal' label='Portal' />
          <DrawerSelect name='originalInsurerName' label='Insurer' />
          <DrawerSelect name='responseStatus' label='Response' />
          <DrawerSelect name='submittedAmount' label='Submitted Amount' />
          <DrawerSelect name='expectedAmount' label='Expected Amount' />
          <DrawerSelect name='arAge' label='Ar Age' />
          <DrawerSelect name='workflow' label='Workflow Status' />
          {flags.claimListTaxIdFilter && (
            <DrawerSelect name='taxId' label='Tax ID' />
          )}
          <FormGroup>
            <DrawerCheckbox
              name='paidSubscriber'
              label='Paid Subscriber Claims Only'
            />
            <DrawerCheckbox name='updated' label='Updated Claims Only' />
            {flags.claimListPostedNotClosedFilter && (
              <DrawerCheckbox
                name='postedNotClosed'
                label='Posted But Not Closed'
              />
            )}
          </FormGroup>
          <ExtendedDivider extend={2} />
          <Button onClick={reset} sx={{ px: 4 }}>
            Reset
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
}
